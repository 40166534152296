import * as React from "react"
import Navbar from "../components/utility/Navbar"
import Footer from "../components/utility/Footer"
import Error from "../components/Error"

// markup
const NotFoundPage = () => {
  return (
    <>
      <Navbar />
      <main>
        <Error />
      </main>
      <Footer />
    </>
  )
}

export default NotFoundPage
