import { Link } from 'gatsby';
import React from 'react'
import "../style/components/error.scss";
import logoImage from "../images/logo.svg";

const Error = () => {
  return (
    <section id='error'>
      <div className='wrapper'>
        <Link className='logo-link' to={"/"}><img className="logo" src={logoImage} alt="Zimmerei Müller" /></Link>
      </div>
      <div className='wrapper'>
        <h1>Webseite nicht gefunden (Page not found)</h1>
        <p >
          Sorry{" "}
          <span role="img" aria-label="Pensive emoji">
            😔
          </span>{" "}
          irgendwie ist hier nicht das, was hier sein sollte... (we couldn’t find what you were looking for).
          <br />
          <Link to="/">Zur Startseite (Go home)</Link>.
        </p>
      </div>
    </section>
  )
}

export default Error